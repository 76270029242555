import { CircularProgress, Grid, Stack } from "@mui/material";
import { useTheme } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdDrawerHeader from "./adDrawerHeader";
import { StyledDivider } from "../../../globalStyle";
import AdCardSection from "./adCardSection";
import TranscriptionSection from "./transcriptionSection";
import { AdDrawerStyledButton, StyledGridItem } from "./style";
import OrganizationBox from "./organizationBox";
import MetadataBox from "./metadataBox";
import CommentBox from "./commentBox";
import { SwipeFileReducerAction } from "../../../store/swipeFile/slice";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import {
  adDetailsSelector,
  downloadMediaLoaderSelector,
  isLoadingSelector,
} from "../../../store/metadata/selector";
import { useEffect } from "react";
import { SwipeFileSagaActions } from "../../../store/swipeFile/sagas";
import DeleteAdModal from "../../modals/deleteAdModal";
import routeConstant from "../../../utils/routeConstant";

const AdDetails = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { libraryId, categoryType } = useParams();

  const isSwipeFile = categoryType === "swipe-ad";

  const loading = useSelector(downloadMediaLoaderSelector);

  const selectedItem = useSelector(adDetailsSelector);
  const itemLoading = useSelector(isLoadingSelector)

  useEffect(() => {
    dispatch(MetaDataSagaActions.fetchAdById(libraryId));
  }, []);

  const handleDelete = () => {
    dispatch(SwipeFileSagaActions.deleteAd());
    navigate(`${routeConstant.swipeFiles}#my-ads`)
  };

  const openDeleteModal = () => {
    dispatch(SwipeFileReducerAction.setDeleteAdsModal(true));
    dispatch(SwipeFileReducerAction.setSelectedDeleteAdId(selectedItem._id));
  };

  const handleDownload = async (adId: string, libraryId: string) => {
    dispatch(MetaDataSagaActions.downloadMediaZipFile({ adId, libraryId }));
  };

  const handleThumbnail = (urlLink: string) => {
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.document.write();
      newTab.document.write(
        `<body 
        style="background-color: black; 
        margin: 0;
        height: 100%;
        width: 100%;"
        >
        <img src="${urlLink}" alt="Image" style="display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        /*top: 190px;*/
        /*left: 450px;*/
        margin: 0 auto;
        background-color: black;
        text-align: center;
        object-fit: contain;"/>
        </body>
        `
      );
      newTab.document.close();
    }
  };

  return (
    itemLoading ? null
    :
    <>
      <AdDrawerHeader
        libraryId={selectedItem?.libraryId}
        dataList={selectedItem ?? []}
        adId={selectedItem?._id}
        transcriptObject={
          Array.isArray(selectedItem?.videoUrls) &&
          selectedItem.videoUrls.length > 0 &&
          !selectedItem?.transcript
        }
      />
      <StyledDivider />
      <Grid
        container
        sx={{
          background: `${theme.colors.gray97} !important`,
        }}
      >
        <AdCardSection
          item={selectedItem}
          showTranscription={Boolean(selectedItem?.transcript)}
        />
        {selectedItem?.transcript && (
          <TranscriptionSection item={selectedItem} />
        )}
        <StyledGridItem
          item
          sm={selectedItem?.transcript ? 4.5 : 5}
          sx={{
            padding: "1.25rem 1.875rem 1.875rem 1.25rem",
            gap: "1.25rem",
            flexDirection: "column",
            background: `${theme.colors.gray97} !important`,
          }}
        >
          <OrganizationBox isSwipeFilePage={isSwipeFile} item={selectedItem} />
          <MetadataBox item={selectedItem} isSwipeFilePage={isSwipeFile} />
          <CommentBox item={selectedItem} />
          <Stack direction="row" alignItems="center" gap="0.625rem">
            <AdDrawerStyledButton
              fullWidth
              onClick={() =>
                handleDownload(selectedItem._id, selectedItem.libraryId)
              }
              disabled={loading}
              sx={{
                backgroundColor: `${theme.colors.background4} !important`,
              }}
              startIcon={
                loading && <CircularProgress size={20} color="inherit" />
              }
            >
              Download
            </AdDrawerStyledButton>
            <AdDrawerStyledButton
              fullWidth
              sx={{
                backgroundColor: `${theme.colors.background4} !important`,
              }}
              disabled={selectedItem?.videoUrls?.length > 0}
              onClick={() => handleThumbnail(selectedItem?.imageUrls[0])}
            >
              Thumbnail
            </AdDrawerStyledButton>
          </Stack>
          {isSwipeFile && (
            <AdDrawerStyledButton
              fullWidth
              borderNone={true}
              textColor="white"
              sx={{
                backgroundColor: `${theme.colors.red200} !important`,
              }}
              onClick={() => openDeleteModal()}
            >
              Delete
            </AdDrawerStyledButton>
          )}
        </StyledGridItem>
      </Grid>
      <DeleteAdModal
        handleDelete={handleDelete}
      />
    </>
  );
};

export default AdDetails;
