import {
  ButtonText,
  Container,
  HeaderSection,
  HeaderSubTitle,
  ListContainer,
} from "../style";
import Header from "../../components/header";
import CreateBoardModal from "../../components/modals/createBoardModal";
import { CustomButton, StyledDivider } from "../../globalStyle";
import {
  LogoutButton,
  SettingsHeaderText,
  SettingsSectionWrapper1,
  SettingsSectionWrapper2,
} from "./style";
import AccountSettingsForm from "../../components/accountSettingsForm";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import routeConstant from "../../utils/routeConstant";
import ResetPasswordModal from "../../components/modals/resetPasswordModal";
import { logoutService } from "../../services/AuthServices";
import { AxiosResponse } from "axios";
import { CircularProgress } from "@mui/material";
import { TeamSettingsForm } from "../../components/teamSettingsForm";
import { CreateNewIcon } from "../../assets/Icons";
import { useTheme } from "styled-components";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { useDispatch, useSelector } from "react-redux";
import CreateTeamModal from "../../components/modals/createTeamModal";
import PlanSection from "../../components/planDetailsSection";
import { AccountSagaActions } from "../../store/account/sagas";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { teamDetailsSelector } from "../../store/metadata/selector";

const Settings = () => {
  // States
  const [loading, setLoading] = useState<boolean>(false);

  // Context
  const { setAuthStatus } = useContext(AuthContext);

  // Selectors
  const teamMembers = useSelector(teamDetailsSelector);

  // Hooks
  const theme = useTheme();
  const navigation = useNavigate();
  const dispatch = useDispatch();

  // Variables
  const content = {
    tabTitle: "Account Settings",
    tabSubTitle: `Having trouble? Contact support through the live chat.`,
  };

  const userDetails = JSON.parse(localStorage.getItem("User") as string);

  // Functions
  const handleLogoutRedirection = async () => {
    setLoading(true);
    const response = (await logoutService()) as AxiosResponse;
    if (response?.status === 200) {
      setAuthStatus(false);
      setLoading(false);
      localStorage.clear();
      navigation(`/${routeConstant.login}`, {
        replace: true,
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(AccountSagaActions.fetchUserDetails(userDetails?.user?._id));
    dispatch(MetaDataSagaActions.teamDetails());
    dispatch(AccountSagaActions.getPlanLists());
  }, []);

  const AccountDetails = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1.875rem",
        }}
      >
        <SettingsSectionWrapper1>
          <SettingsSectionWrapper2>
            <SettingsHeaderText>Account Details</SettingsHeaderText>
            <HeaderSubTitle>Update your Account information.</HeaderSubTitle>
            <StyledDivider sx={{ margin: "20px 0 40px" }} />
            <AccountSettingsForm />
          </SettingsSectionWrapper2>
          <LogoutButton
            onClick={handleLogoutRedirection}
            disabled={loading}
            startIcon={
              loading && <CircularProgress size={20} color="inherit" />
            }
          >
            Logout
          </LogoutButton>
        </SettingsSectionWrapper1>
        <SettingsSectionWrapper1>
          <SettingsSectionWrapper2>
            <SettingsHeaderText>Team Settings</SettingsHeaderText>
            <HeaderSubTitle>
              Let's get started by adding members to your team.
            </HeaderSubTitle>
            <StyledDivider sx={{ margin: "20px 0 40px" }} />
            <TeamSettingsForm />
          </SettingsSectionWrapper2>
          {teamMembers.length &&
            <CustomButton
              variant="contained"
              size="small"
              sx={{
                position: "absolute",
                top: "1.875rem",
                right: "16.188rem",
                padding: "0.625rem 1.25rem",
                fontSize: "1rem",
                fontWeight: "500",
                borderRadius: "1.786rem !important",
                color: "white !important",
                background: `${theme.colors.backgroundPrimary} !important`,
                height: "2.6rem",
                cursor: teamMembers.length > 0 ? "not-allowed" : "pointer",
              }}
              onClick={() =>
                teamMembers.length === 0 && dispatch(MetaDataReducerAction.setOpenCreateTeamModal(true))
              }
            >
              <CreateNewIcon fill="white" />
              <ButtonText>Create Team</ButtonText>
            </CustomButton>
          }
        </SettingsSectionWrapper1>
        <SettingsSectionWrapper1>
          <SettingsSectionWrapper2>
            <SettingsHeaderText>My Plans</SettingsHeaderText>
            <HeaderSubTitle>
              For user Subscriptions History and Renewal.
            </HeaderSubTitle>
            <StyledDivider sx={{ margin: "20px 0 40px" }} />
            <PlanSection />
          </SettingsSectionWrapper2>
          <CustomButton
            variant="contained"
            size="small"
            sx={{
              position: "absolute",
              top: "1.875rem",
              right: "16.188rem",
              padding: "0.625rem 1.25rem",
              fontSize: "1rem",
              fontWeight: "500",
              borderRadius: "1.786rem !important",
              color: "white !important",
              background: `${theme.colors.backgroundPrimary} !important`,
              height: "2.6rem",
            }}
            onClick={() => navigation(`${routeConstant.paymentHistory}`)}
          >
            <ButtonText>View Payment History</ButtonText>
          </CustomButton>
        </SettingsSectionWrapper1>
        <ResetPasswordModal />
        <CreateTeamModal />
      </div>
    );
  };

  return (
    <Container>
      <HeaderSection>
        <Header content={content} hideCreateButton={true} />
        <ListContainer>
          <AccountDetails />
        </ListContainer>
      </HeaderSection>
      <CreateBoardModal />
    </Container>
  );
};

export default Settings;
