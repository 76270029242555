import axios from "axios";
import axiosClient from "../apiClient";

export const CreateTeamService = async (params: {
  name: string;
  logo: File;
}) => {
  try {
    const reqBody = params;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: localStorage.getItem("AccessToken"),
      },
    };
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/team`,
      reqBody,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const TeamDetailsService = async () => {
  try {
    const response = await axiosClient.get("/team");
    return response;
  } catch (error) {
    return error;
  }
};

export const TeamRemoveMemberService = async (
  userEmail: string,
  folderId: string
) => {
  try {
    const response = await axiosClient.get(
      `/folder/remove-member?userEmail=${userEmail}&folderId=${folderId}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const SendInvitationService = async (params: {
  teamId: string;
  email: string;
}) => {
  try {
    const response = await axiosClient.post(
      `/team/add-member/${params.teamId}`,
      { email: params.email }
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const JoinTeamService = async (params: {
  notificationId: string;
  teamId: string;
  status: boolean;
}) => {
  try {
    const body = {
      accept: params.status,
      notificationId: params.notificationId,
    };
    const response = await axiosClient.put(`/team/join/${params.teamId}`, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const DeleteMember = async (params: { email: string }) => {
  try {
    const response = await axiosClient.get(
      `/team/remove-member/${params.email}`
    );
    return response;
  } catch (error) {
    return error;
  }
};
