import {
  PanelItem,
  PanelLogo,
  SidebarWrapper,
  PanelLogoWrapper,
} from "../../pageComponents/auth/style";
import {
  DashboardIcon,
  DiscoveryIcon,
  LogoSmall,
  SwipeFileIcon,
} from "../../assets/Icons";
import { Typography } from "@mui/material";
import Logo from "../../assets/images/Logo.png";
import useConfig from "../../hooks/useConfig";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import SidebarFolder from "../sidebarFolder";
import routeConstant from "../../utils/routeConstant";
import AddUserModal from "../addUserModal";
import SidebarSharedFolders from "../sidebarSharedFolder";

const drawerData = [
  {
    name: "Dashboard",
    icon: <DashboardIcon />,
    link: routeConstant.dashboard,
  },
  {
    name: "Swiped Ads",
    icon: <SwipeFileIcon />,
    link: routeConstant.swipeFiles,
  },
  {
    name: "Explore Lounge",
    icon: <DiscoveryIcon />,
    link: routeConstant.discovery,
  },
];
const Sidebar = () => {
  const { drawerOpen } = useConfig();
  const { pathname } = useLocation();
  const { folderId } = useParams();
  const CLICKED_URL = "/" + pathname.split("/")[1];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MetaDataSagaActions.fetchSharedFolderList());
  }, []);

  useEffect(() => {
    if(!pathname.includes("myFolders")){
      dispatch(
        MetaDataSagaActions.fetchFolderList({
          selectedFolderId: folderId,
          selectedBoardId: null,
        })
      );
    }
  }, [folderId]);

  return (
    <SidebarWrapper id="side-panel" drawerOpen={drawerOpen}>
      {drawerOpen ? (
        <PanelLogo src={Logo} alt="brand-logo" />
      ) : (
        <PanelLogoWrapper>
          <LogoSmall />
        </PanelLogoWrapper>
      )}
      {drawerData.map((item, index: number) => (
        <PanelItem
          key={index}
          to={item?.link}
          drawerOpen={drawerOpen}
          style={{
            background:
              item?.link === CLICKED_URL
                ? "linear-gradient(94.04deg, #1c75bc 2.44%, #00aeef 112.73%)"
                : "transparent",
          }}
        >
          {item?.icon}
          {item?.name && drawerOpen && (
            <Typography style={{ marginLeft: "0.857rem" }}>
              {item?.name}
            </Typography>
          )}
        </PanelItem>
      ))}

      {drawerOpen && (
        <>
          <SidebarFolder />
          <SidebarSharedFolders />
        </>
      )}

      <AddUserModal />
    </SidebarWrapper>
  );
};

export default Sidebar;
