import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.metaDataState || initialState;
export const isLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);

export const openCreateFolderSelector = createSelector(
  selectDomain,
  (state) => state.openCreateFolder
);

export const boardAdsItemsSelector = createSelector(
  selectDomain,
  (state) => state.boardAdsItems
);

export const folderAccessMemberListSelector = createSelector(
  selectDomain,
  (state) => state.folderAccessMemberList
);

export const folderTeamMemberListSelector = createSelector(
  selectDomain,
  (state) => state.folderTeamMemberList
);
export const isRemoveUserModalOpenSelector = createSelector(
  selectDomain,
  (state) => state.isRemoveUserModalOpen
);

export const folderListsSelector = createSelector(
  selectDomain,
  (state) => state.folderLists
);

export const boardItemsSelector = createSelector(
  selectDomain,
  (state) => state.boardItems
);

export const boardIdsSelector = createSelector(
  selectDomain,
  (state) => state.boardIds
);

export const openBoardModalSelector = createSelector(
  selectDomain,
  (state) => state.openBoardModal
);

export const selectFolderSelector = createSelector(
  selectDomain,
  (state) => state.selectedFolder
);

export const selectedSharedFolderSelector = createSelector(
  selectDomain,
  (state) => state.selectedSharedFolder
);

export const selectMultipleBoardSelector = createSelector(
  selectDomain,
  (state) => state.selectMultipleBoards
);

export const TagsListsSelector = createSelector(
  selectDomain,
  (state) => state.tagsLists
);

export const brandListsSelector = createSelector(
  selectDomain,
  (state) => state.brandsList
);

export const saveAdPopupAnchorElSelector = createSelector(
  selectDomain,
  (state) => state.saveAdPopupAnchorEl
);

export const selectedAdIdSelector = createSelector(
  selectDomain,
  (state) => state.selectedSaveAdId
);

export const adDetailsSelector = createSelector(
  selectDomain,
  (state) => state.adDetailsData
);

export const favouriteBrandsSelector = createSelector(
  selectDomain,
  (state) => state.favouriteBrands
);

export const favouriteBrandIdsSelector = createSelector(
  selectDomain,
  (state) => state.favouriteBrandIds
);

export const adNavigationIndexSelector = createSelector(
  selectDomain,
  (state) => state.adNavigationIndex
);

export const brandFilterSearchTextSelector = createSelector(
  selectDomain,
  (state) => state.brandFilterSearchText
);

export const boardFilterSearchTextSelector = createSelector(
  selectDomain,
  (state) => state.boardFilterSearchText
);

export const currentPlayingVideoSelector = createSelector(
  selectDomain,
  (state) => state.currenplayingVideoState
);

export const openSaveTranscriptSelector = createSelector(
  selectDomain,
  (state) => state.openSaveTranscriptModal
);

export const saveTranscriptLoaderSelector = createSelector(
  selectDomain,
  (state) => state.saveTranscriptLoader
);

export const favouriteFolderListSelector = createSelector(
  selectDomain,
  (state) => state.favouriteFolderList
);

export const openSaveNewAdSelector = createSelector(
  selectDomain,
  (state) => state.openSaveNewAd
);

export const openSaveNewAdLoaderSelector = createSelector(
  selectDomain,
  (state) => state.openSaveNewAdLoader
);

export const modalTabIndexSelector = createSelector(
  selectDomain,
  (state) => state.modalTabIndex
);
export const downloadMediaLoaderSelector = createSelector(
  selectDomain,
  (state) => state.downloadMediaLoader
);

export const folderItemSelector = createSelector(
  selectDomain,
  (state) => state.folderItem
);

export const openBoardDropdownSelector = createSelector(
  selectDomain,
  (state) => state.openBoardDropdown
);

export const tagFilterSearchTextSelector = createSelector(
  selectDomain,
  (state) => state.tagFilterSearchText
);

export const deleteFolderLoadingSelector = createSelector(
  selectDomain,
  (state) => state.deleteFolderLoading
);

export const openViewFolderModalSelector = createSelector(
  selectDomain,
  (state) => state.openViewFolderModal
);

export const openCreateTeamModalSelector = createSelector(
  selectDomain,
  (state) => state.openCreateTeamModal
);

export const createTeamLoadingSelector = createSelector(
  selectDomain,
  (state) => state.createTeamLoading
);
export const teamDetailsSelector = createSelector(
  selectDomain,
  (state) => state.teamDetails
);

export const invitationLoaderSelector = createSelector(
  selectDomain,
  (state) => state.invitationLoader
);

export const deleteMemberLoaderSelector = createSelector(
  selectDomain,
  (state) => state.removeMemberLoader
);

export const notificationListSelector = createSelector(
  selectDomain,
  (state) => state.notificationList
);

export const checkNotificationStatusSelector = createSelector(
  selectDomain,
  (state) => state.checkNotificationStatus
);

export const acceptJoinTeamLoaderSelector = createSelector(
  selectDomain,
  (state) => state.acceptJoinTeamLoader
);

export const rejectJoinTeamLoaderSelector = createSelector(
  selectDomain,
  (state) => state.rejectJoinTeamLoader
);

export const teamMemberEmailSelector = createSelector(
  selectDomain,
  (state) => state.teamMemberEmail
);

export const notificationLoaderSelector = createSelector(
  selectDomain,
  (state) => state.notificationLoader
);

export const selectedFolderIdSelector = createSelector(
  selectDomain,
  (state) => state.selectedFolderId
);
export const selectedSharedFolderIdSelector = createSelector(
  selectDomain,
  (state) => state.selectedSharedFolderId
);

export const sharedFolderListSelector = createSelector(
  selectDomain,
  (state) => state.sharedFolderList
);

export const sharedBoardAdsItemsSelector = createSelector(
  selectDomain,
  (state) => state.sharedBoardAdsItems
);

export const boardAdsSearchSelector = createSelector(
  selectDomain,
  (state) => state.search
);

export const filterOptionSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions
);

/* export const swipeFileSelectedBrandsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedPageIds
); */

/* export const swipeFileSelectedPlatformsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedPlatformIds
); */

export const swipeFileSelectedBoardsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedBoardIds
);

export const selectedTagsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedTagIds
);

export const selectedBrandsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedPageIds
);

export const selectedPlatformsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedPlatformIds
);
export const selectedTabIndexSelector = createSelector(
  selectDomain,
  (state) => state.selectedTabIndex
);

export const boardAdsFilterCountSelector = createSelector(
  selectDomain,
  (state) => {
    const {
      selectedPlatformIds,
      selectedPageIds,
      selectedBoardIds,
      selectedTagIds,
    } = state.filterOptions;
    return (
      selectedPlatformIds.length +
      selectedPageIds.length +
      selectedBoardIds.length +
      selectedTagIds.length
    );
  }
);
export const removeTeamMemberSelector = createSelector(
  selectDomain,
  (state) => state.removeTeamMember
);
