import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import {
  AdDrawerStyledButton,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./style";
import {
  ArrowDownIcon,
  BoardIcon,
  CreateNewIcon,
  StarIcon,
  TagsIcon,
} from "../../../assets/Icons";
import { useTheme } from "styled-components";
import SelectBoardDropdown from "../selectBoardsDropdown";
import RatingsDropdown from "../ratingsDropdown";
import AddTagPopover from "../../addTagPopover";
import { useDispatch, useSelector } from "react-redux";
import { StyledChip } from "../../../globalStyle";
import { adDetailsSelector } from "../../../store/metadata/selector";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import { DiscoveryItem } from "../../../store/discovery/types";
import { SwipeFileListItems } from "../../../store/swipeFile/types";
import { BoardItem, tagItem } from "../../../store/metadata/types";

const OrganizationBox = ({
  isSwipeFilePage,
  item,
}: {
  isSwipeFilePage: boolean;
  item: DiscoveryItem | SwipeFileListItems;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [addTagPopupAnchorEl, setAddTagPopupAnchorEl] =
    useState<Element | null>(null);
  const [expanded, setExpanded] = useState(true);
  const adDetailsData = useSelector(adDetailsSelector);

  const handleUpdateBoard = (
    selectedBoardIds: string[],
    removedBoardId: string[]
  ) => {
    dispatch(
      MetaDataSagaActions.saveAdToBoards({
        adId: item._id,
        boardIds: selectedBoardIds,
        removeBoardIds: removedBoardId,
      })
    );
  };

  return (
    <>
      <Box>
        <StyledAccordion
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <StyledAccordionSummary
            expandIcon={<ArrowDownIcon />}
            sx={{ padding: "1.25rem" }}
          >
            Organization
          </StyledAccordionSummary>
          <StyledAccordionDetails
            sx={{
              padding: "1.25rem",
              gap: "1.25rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" gap="0.5rem">
                <BoardIcon fill={theme.colors.gray1} />
                <Typography
                  fontSize="1rem"
                  fontWeight="500"
                  color={theme.colors.gray1}
                >
                  Save to Boards
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                width="60%"
                gap="0.75rem"
              >
                <SelectBoardDropdown
                  showSelectedItemCount={2}
                  selectedBoards={adDetailsData?.board}
                  selectedBoardIds={adDetailsData?.board?.map(
                    (item: BoardItem) => item._id
                  )}
                  handleUpdateBoard={handleUpdateBoard}
                />
              </Stack>
            </Stack>

            {isSwipeFilePage && (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" gap="0.5rem">
                    <StarIcon />
                    <Typography
                      fontSize="1rem"
                      fontWeight="500"
                      color={theme.colors.gray1}
                    >
                      Rating
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    width="60%"
                    gap="0.75rem"
                  >
                    <RatingsDropdown
                      adId={item?._id}
                      ratingValue={
                        adDetailsData?.ratings &&
                        adDetailsData?.ratings[0]?.rating
                      }
                    />
                  </Stack>
                </Stack>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" alignItems="center" gap="0.5rem">
                    <TagsIcon />
                    <Typography
                      fontSize="1rem"
                      fontWeight="500"
                      color={theme.colors.gray1}
                    >
                      Tags
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    width="60%"
                    gap="0.75rem"
                  >
                    {adDetailsData?.Tags?.slice(0, 1).map(
                      (tagItem: tagItem) => (
                        <AdDrawerStyledButton
                          key={tagItem._id}
                          disableTouchRipple
                          borderNone={true}
                          sx={{
                            backgroundColor: `${theme.colors.background4} !important`,
                          }}
                        >
                          {tagItem.name}
                        </AdDrawerStyledButton>
                      )
                    )}
                    {adDetailsData?.Tags?.length > 1 && (
                      <StyledChip
                        label={`+${adDetailsData.Tags.length - 1}`}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          setAddTagPopupAnchorEl(event.currentTarget);
                        }}
                      />
                    )}
                    <AdDrawerStyledButton
                      borderNone={true}
                      sx={{
                        backgroundColor: `${theme.colors.background4} !important`,
                      }}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setAddTagPopupAnchorEl(event.currentTarget);
                      }}
                    >
                      <CreateNewIcon fill={theme.colors.black2} />
                        {adDetailsData?.Tags?.length > 0 ? 'Manage Tag' : 'Add Tag'}
                    </AdDrawerStyledButton>
                  </Stack>
                </Stack>
              </>
            )}
          </StyledAccordionDetails>
        </StyledAccordion>
      </Box>
      <AddTagPopover
        anchorEl={addTagPopupAnchorEl}
        handlePopoverClose={() => setAddTagPopupAnchorEl(null)}
        item={adDetailsData}
      />
    </>
  );
};

export default OrganizationBox;
